function fetchCurrencies(destiantion) {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: '/destination_countries/' + destiantion + '/currencies',
      type: 'GET',
      success: function (response) {
        resolve(response);
      },
      error: function (xhr, status, error) {
        console.error("AJAX Error: " + status + error);
        reject(error);
      }
    });
  });
}

function populateCurrencies(currencies = []) {
  const emptySelectContent = '<option value="">Please select</option>';
  const currenciesEL = $('#entity_account_destination_currency_id');
  currenciesEL.empty().html(emptySelectContent);
  const content = currencies.map(cr => `<option value="${cr.id}">${cr.name}</option>`).join('');
  currenciesEL.append(content);
}

$(() => {

  $('#destination_country').on('change', function () {
    const value = $(this).val();
    if (value) {
      fetchCurrencies(value)
        .then(resp => {
          populateCurrencies(resp.destination_currencies || [])
        });
    }
    else populateCurrencies();
  });

  $('#new-coa-account-button').on('click', function () {
    const accountId = $('#parent_account_id').val();
    $.ajax({
      url: '/accounts/new?account_id=' + accountId,
      type: "GET",
      error: function (xhr, status, error) {
        console.error("AJAX Error: " + status + error);
      },
      success: function (response) {
        if (response) {
          $('#new-coa-account-modal .modal-body').empty().html(response.replace(/max-w-xs|md:w-5\/12/g, ''));
          $('#new-coa-account-modal-trigger').trigger('click');
        }
      }
    });
  });

  $('#coa_no_dropdown').on('change', function () {
    const selectedText = $(this).find('option:selected').text();
    // console.log("Selected text:", selectedText);
    const data = selectedText.split('|');
    const coa_no = $('#entity_account_coa_no');
    const coa_name = $('#entity_account_coa_name');
    if (data.length < 3) {
      coa_no.val('');
      coa_name.val('');
    }
    else {
      coa_no.val(data[1].trim());
      coa_name.val(data[0].trim());
    }
  });
})
